// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
// import profile from '../../assets/images/avatar.png'
var EnvName = 'production';
var key = {};
key.ONEDAYINSECONDS = 0
key.env = EnvName
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil = /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.IPFS = 'https://ipfs.io/ipfs/'
key.DecimalAlloweddigits = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if (EnvName === "demo") {
}
else if (EnvName === "stage") {
}
else if (EnvName === "production") {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'http://localhost:3000'
    key.BACK_URL = 'https://api.homecubes.io/v1/front'
    key.ADMIN_URL = 'https://api.homecubes.io/v1/admin'
    key.IMG_URL = 'https://api.homecubes.io'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    // key.TradeContract   =   '0xD370f5D497cc4b2344a9936f24E47284693d96D5'.toLowerCase() // bnb
    // key.TradeContract = '0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3'.toLowerCase() //sepolia
    // key.TradeContract = '0x19D4c0f9155C6517580f850D4D097AF0448a1B39'.toLowerCase() //sepolia old
    key.TradeContract = '0x274C7D841002A74c3E4EABEcDB504e3af3f1f05A'.toLowerCase() //sepolia
    key.ERC721 = '0x575cd9E4099A38B536673F557063f9A546870d11'.toLowerCase() // sepolia
    // key.ERC721          =   '0x6fbcF768b6E0cf9ED6Cc38ad41EDb939E925deaf'.toLowerCase() //bnb
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s1.binance.org:8545/"
    // key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List = [97, 11155111]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 11155111
    key.RPC_URL = "https://ethereum-sepolia.publicnode.com"
    key.CHAIN_ID = 11155111
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
else if (EnvName === "demossl") {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'https://homecube-build-testing.pages.dev/'
    key.BACK_URL = 'https://backend-homecubes.maticz.in/v1/front'
    key.ADMIN_URL = 'https://backend-homecubes.maticz.in/v1/admin'
    key.IMG_URL = 'https://backend-homecubes.maticz.in'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    // key.TradeContract   =   '0xD370f5D497cc4b2344a9936f24E47284693d96D5'.toLowerCase() // bnb
    // key.TradeContract = '0x19D4c0f9155C6517580f850D4D097AF0448a1B39'.toLowerCase() //sepolia old one
    key.TradeContract = '0x274C7D841002A74c3E4EABEcDB504e3af3f1f05A'.toLowerCase() //sepolia old one
    // key.TradeContract = '0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3'.toLowerCase() //sepolia
    key.ERC721 = '0x575cd9E4099A38B536673F557063f9A546870d11'.toLowerCase() // sepolia
    // key.ERC721          =   '0x6fbcF768b6E0cf9ED6Cc38ad41EDb939E925deaf'.toLowerCase() //bnb
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://data-seed-prebsc-2-s1.binance.org:8545/"
    key.chain_Id_List = [97, 11155111]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 11155111
    key.RPC_URL = "https://ethereum-sepolia.publicnode.com"
    key.CHAIN_ID = 11155111
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
else {
    key.KEY = 'MNBVCZX1234)(*'
    key.FRONT_URL = 'http://localhost:3000'
    key.BACK_URL = 'http://localhost:3030/v1/front'
    key.ADMIN_URL = 'http://localhost:3030/v1/admin' 
    key.IMG_URL = 'http://localhost:3030'
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    // key.profile         =    profile
    // key.TradeContract   =   '0xD370f5D497cc4b2344a9936f24E47284693d96D5'.toLowerCase() // bnb
    // key.TradeContract = '0xD87ddfE179fE3e2Aea84041118a7E6C7EC975fe3'.toLowerCase() //sepolia
    // key.TradeContract = '0x19D4c0f9155C6517580f850D4D097AF0448a1B39'.toLowerCase() //sepolia old
    key.TradeContract = '0x274C7D841002A74c3E4EABEcDB504e3af3f1f05A'.toLowerCase() //sepolia
    key.ERC721 = '0x575cd9E4099A38B536673F557063f9A546870d11'.toLowerCase() // sepolia
    // key.ERC721          =   '0x6fbcF768b6E0cf9ED6Cc38ad41EDb939E925deaf'.toLowerCase() //bnb
    key.ERC1155 = '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address = '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s1.binance.org:8545/"
    // key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List = [97, 11155111]
    key.BNBCHAIN = 97
    key.ETHCHAIN = 11155111
    key.RPC_URL = "https://ethereum-sepolia.publicnode.com"
    key.CHAIN_ID = 11155111
    key.COIN_NAME = "BNB"
    key.Block_URL = {
        ETH: "https://testnet.snowtrace.io/",
        BNB: "https://testnet.bscscan.com/"
    }
}
export default key;
